import { useSelector } from "react-redux";
import SubAdminList from "../component/SubAdminList";
import AccessDenied from "../component/AccessDenied";
import { isTaskAllowed } from "../utils/isTaskAllowed";

const SubAdmin = () => {
    const { admin } = useSelector((state) => state.auth);

    if((admin?.email !== "admin@thriftyx.com") &&
        !isTaskAllowed("Sub Admin", admin?.assignedWork)) return (<AccessDenied />)
            
    return (<SubAdminList />)
}
export default SubAdmin;