import Table from "./Table";
import Moment from "moment";
import PageLoader from './PageLoader';
import debounce from "lodash.debounce";
import { makeStyles } from "@mui/styles";
import { FaSearch } from "react-icons/fa";
import { IoMdDownload } from "react-icons/io";
import exportFromJSON from 'export-from-json';
import { timeConvert } from "../utils/timeConvert";
import { getOrders } from "../redux/user/user.slice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useCallback, useMemo } from "react";

const useStyles = makeStyles({
    searchInput: {
        marginBottom: 16,
    },
    searchIcon: {
        right: 16,
        top: 4
    }
});

const heading = ["SNo.", "Order Id", "Receipt No", "Date", "Status", "Billing Detail", "User Detail", "Event Detail"];

const OrderList = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [itemLimit, setItemLimit] = useState(10);
    const [searchText, setSearchText] = useState("");

    const { isLoading, totalCount, orderData } = useSelector((state) => state.user);

    useEffect(() => {
        dispatch(getOrders({limit, offset, searchText}));
    },[dispatch, limit, offset, searchText]); 

    const debouncedSave = useMemo(() => 
        debounce((value) => setSearchText(value), 1000), 
        [setSearchText]
    );

    const handleSearchText = useCallback((event, itemCount) => {
        if(limit === itemCount && offset === 0){
            debouncedSave(event.target.value);
        }
        else{
            setLimit(itemCount);
            setOffset(0);
        }
    }, [limit, offset, debouncedSave]);

    const nextClicked = (l, o, itemCount) => {
        setLimit(l+itemCount);
        setOffset(o+itemCount);
    }

    const prevClicked = (l, o, itemCount) => {
        setLimit(l-itemCount);
        setOffset(o-itemCount);
    }

    const handleRadioChange = (e, itemCount) => {
        setOffset(0);
        setLimit(itemCount);
        e.target.value === "All" ? setSearchText("") : setSearchText(e.target.value)
    }

    const handleLimitChange = (e) => {
        setLimit(Number(e.target.value));
        setItemLimit(Number(e.target.value));
    }

    const downloadCSV = () => {
        const newOrderData = orderData?.map((item, index) => {
            return {
                index: index + 1,
                updatedBy: Moment(item.updatedBy).format("MMM DD YYYY") ?? '-',
                order_id: item.order_id ?? '-',
                // receipt_no: item.receipt_no ?? '-',
                payment_id: item.payment_id ?? '-',
                total_price: item.total_price ?? '-',
                booking_fee: item.booking_fee ?? '-',
                gst: item.gst ?? '-',
                grand_total: item.grand_total ?? '-',
                status: item.status ?? '-',
                tickets: JSON.stringify(item.tickets) ?? '-',
                user_name: item.user_id.name ?? '-',
                user_email: item.user_id.email ?? '-',
                user_phone: item.user_id.phone ?? '-',
                event_name: item?.event_id?.name ?? '-',
                event_city: item?.event_id?.city ?? '-',
                venue_name: item?.event_id?.venue_name ?? '-',
                venue_address: item?.event_id?.venue ?? '-',
                event_date: item?.event_id?.date ? 
                    Moment(item?.event_id?.date)?.format("MMM DD YYYY") : '-',
                start_time: item?.event_id?.start_time ? 
                    timeConvert(item?.event_id?.start_time) : '-',
                invoice: item.invoice_and_tickets ?? '-'
            }
        })
        const fileName = 'OrderList';
        const fieldNames = {
            index: "SNo.",
            updatedBy: "Date", 
            order_id: "Order ID", 
            // receipt_no: "Receipt No",
            payment_id: "Payment ID",
            total_price: "Total Ticket Price",
            booking_fee: "Booking Fees",
            gst: "GST (18%)",
            grand_total: "Grand Total",
            status: "Status",
            tickets: "Tickets",
            user_name: "Name",
            user_email: "Email",
            user_phone: "Phone",
            event_name: "Event Name",
            event_city: "Event City",
            venue_name: "Venue Name",
            venue_address: "Venue Address",
            event_date: "Event Date",
            start_time: "Start Time",
            invoice: "Invoice And Ticket Url"
        }
        const exportType =  exportFromJSON.types.csv;
        exportFromJSON({ data: newOrderData, fileName, fields: fieldNames, exportType });
    }

    if(isLoading) return ( <PageLoader />)

    return (
        <>
            <div className="container mt-80">
                <div className="position-relative">
                    <div className={`${classes.searchIcon} position-absolute`}>
                        <FaSearch />
                    </div>
                    <input
                        type="text"
                        onChange={(e) => handleSearchText(e, itemLimit)}
                        className={`${classes.searchInput} form-control`} 
                        placeholder="Search order" 
                    />
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <div className="form-check form-check-inline">
                            <input
                                value="All"
                                type="radio"
                                id="inlineRadio0"
                                name="inlineRadioOptions"
                                checked={searchText === ""}
                                className="form-check-input" 
                                onChange={(e) => handleRadioChange(e, itemLimit)}
                            />
                            <label
                                htmlFor="inlineRadio0"
                                className="form-check-label fw-bold" 
                            >
                                All
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                type="radio"
                                value="Failed"
                                id="inlineRadio1"
                                name="inlineRadioOptions"
                                className="form-check-input"
                                checked={searchText === "Failed"}
                                onChange={(e) => handleRadioChange(e, itemLimit)}
                            />
                            <label
                                htmlFor="inlineRadio1"
                                className="form-check-label fw-bold" 
                            >
                                Failed
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                type="radio"
                                value="Progress"
                                id="inlineRadio2"
                                name="inlineRadioOptions"
                                className="form-check-input"
                                checked={searchText === "Progress"}
                                onChange={(e) => handleRadioChange(e, itemLimit)}
                            />
                            <label
                                htmlFor="inlineRadio2"
                                className="form-check-label fw-bold" 
                            >
                                Progress
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                type="radio"
                                id="inlineRadio3"
                                value="Completed"
                                name="inlineRadioOptions"
                                className="form-check-input"
                                checked={searchText === "Completed"}
                                onChange={(e) => handleRadioChange(e, itemLimit)}
                            />
                            <label
                                htmlFor="inlineRadio3"
                                className="form-check-label fw-bold" 
                            >
                                Completed
                            </label>
                        </div>
                    </div>
                    <div>
                        <button
                            onClick={downloadCSV}
                            className="download-csv"
                        >
                            Download CSV <IoMdDownload fontSize="large" />
                        </button>
                    </div>
                </div>
                <Table
                    limit={limit}
                    offset={offset}
                    data={orderData}
                    heading={heading}
                    tableFor="orders"
                    itemLimit={itemLimit}
                    totalCount={totalCount}
                    handleLimitChange={(e) => handleLimitChange(e)}
                    next={(limit, offset) => nextClicked(limit, offset, itemLimit)}
                    previous={(limit, offset) => prevClicked(limit, offset, itemLimit)}
                />
            </div>
        </>
    )
}
export default OrderList;